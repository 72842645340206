import React from 'react';
import ReactGA from '../../../utils/google-analytics';
import Layout from '../../../components/layout/Layout';
import Head from '../../../components/layout/Head';
import ReviewFormStatus from '../../../components/organisms/Form/ReviewFormStatus';
import AuthWrapper from '../../../components/layout/AuthWrapper';
import { getQueryVar } from '../../../utils/query-vars';

const isBrowser = typeof window !== 'undefined';

const LearnerReviewCompletedFormPage = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const learnerid = getQueryVar('lid');
  const learnerFullName = `${getQueryVar('lfn')} ${getQueryVar('lln')}`;
  return (
    <AuthWrapper userRole="admin">
      <Head title="Review Form" />
      <Layout
        section="learners"
        breadcrumbs={[
          { link: '/', label: 'Home' },
          { link: '/list-learners', label: 'Learners' },
          {
            link: `/learner/?lid=${learnerid}&section=tasks`,
            label: learnerFullName,
          },
          { label: 'Review form' },
        ]}
      >
        <ReviewFormStatus />
      </Layout>
    </AuthWrapper>
  );
};

export default LearnerReviewCompletedFormPage;
